import Plausible from "plausible-tracker";

const domains: Record<string, string> = {
  "acc.alive.healthyworkers.com": "acc.alive.healthyworkers.com",
  "staging.alive.healthyworkers.com": "acc.alive.healthyworkers.com",
  "alive.healthyworkers.com": "app.healthyworkers.com",
  "app.healthyworkers.com": "app.healthyworkers.com",
  "smabu.batenburg.nl": "smabu.batenburg.nl",
  "vted.healthyworkers.com": "app.vantilburgbv.nl",
  "app.vantilburgbv.nl": "app.vantilburgbv.nl"
};

export const plausible = Plausible({
  domain:
    import.meta.env.MODE === "production"
      ? domains[window.location.hostname]
      : domains["staging.alive.healthyworkers.com"],
  apiHost: window.location.origin,
  trackLocalhost: false
});
